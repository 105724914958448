<template>
  <div class="note">
    <img src="../../assets/product/note/1.png" class="headerimg" />

    <div class="contertop">
      <div class="title">应用场景</div>

      <div class="BuildStationlist">
        <div class="BuildStationcard">
          <img src="../../assets/product/note/2.png" class="cardimg" />
          <div class="cardtext">验证码</div>
          <div class="cardinfo">
            <div class="text">登陆、注册、确认、预约</div>
          </div>
        </div>

        <div class="BuildStationcard">
          <img src="../../assets/product/note/3.png" class="cardimg" />
          <div class="cardtext">行业通知</div>
          <div class="cardinfo">
            <div class="text">快递通知、订单通知、消息提醒</div>
          </div>
        </div>

        <div class="BuildStationcard">
          <img src="../../assets/product/note/4.png" class="cardimg" />
          <div class="cardtext">会员营销</div>
          <div class="cardinfo">
            <div class="text">用户关怀、新品推广</div>
          </div>
        </div>
      </div>
    </div>

    <div class="contertop contertopimg">
      <img src="../../assets/product/note/5.png" alt="" />
    </div>

    <div class="contertop conterbottom">
      <div class="title">平台优势</div>

      <div class="advantagelist">
        <div class="advantagecard">
          <div class="cardimg">
            <img src="../../assets/product/note/6.png" alt="" />
          </div>
          <div class="cardtitle">毫秒级接口速率</div>
          <div class="cardinfo">
           <p>采用阿里云SLB负载均衡</p>
            <p class="cardline">几十台接口服务随时待命</p>
            <p>可支持万级的TPS</p>
          </div>
        </div>

        <div class="advantagecard">
          <div class="cardimg">
            <img src="../../assets/product/note/7.png" alt="" />
          </div>
          <div class="cardtitle">99.99%的到达率</div>
          <div class="cardinfo">
             <p>系统对失败消息自动补发</p>
            <p class="cardline">自动支持携号转网发送</p>
            <p>每条发送通道配备备用通道</p>
          </div>
        </div>

        <div class="advantagecard">
          <div class="cardimg">
            <img src="../../assets/product/note/8.png" alt="" />
          </div>
          <div class="cardtitle">5秒内稳定送达</div>
          <div class="cardinfo">
            <p>分布式、高并发处理</p>
            <p class="cardline">高速缓存、高速队列为依托</p>
            <p>多通道同时发送</p>
          </div>  
        </div>

        <div class="advantagecard">
          <div class="cardimg">
            <img src="../../assets/product/note/9.png" alt="" />
          </div>
          <div class="cardtitle">7/24的安全保障</div>
          <div class="cardinfo">
            <p>以Prometheus为核心的全面监控</p>
            <p class="cardline">系统对到达率、响应时间实时预警</p>
            <p>分布式集群部署，无单节点瓶颈</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.contertopimg {
  text-align: center;
  img {
    width: 1400px;
  }
}

.advantagelist {
  justify-content: space-between !important;
  width: 80%;
  margin: auto;
  margin-top: 60px !important;
  .advantagecard {
    margin-left: 0px !important;
    width: 325px;
    height: 335px;
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.2);
    text-align: center;
    .cardimg {
      margin-top: 60px;
      img {
        width: 70px;
      }
    }
    .cardtitle {
      font-size: 24px;
      font-weight: 500;
      line-height: 36px;
      margin-top: 40px;
    }
    .cardinfo {
      margin: auto 17px;
      margin-top: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #616b80;
      line-height: 20px;
      .cardline{
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }
}

.BuildStationlist {
  margin: auto;
  margin-top: 60px;
  width: 60%;
  display: flex;
  justify-content: space-between;
  .BuildStationcard {
    text-align: center;
    .cardimg {
      width: 187px;
    }
    .BuildStationcardimg {
      width: 315px;
    }
    .cardtext {
      margin-top: 30px;
      font-size: 24px;
      font-weight: 500;
      line-height: 36px;
    }
    .cardinfo {
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      line-height: 21px;
      margin-top: 14px;
    }
  }
}
</style>